import "@learnics/services/src/yjsProvider/initializeCommonYjsPackage";
import Vue from "vue";
import { saveWebsiteError } from "@/utils/saveWebsiteError";

export function initializeGlobalWebsiteErrorHandlers() {
  console.log("Initializing global error handlers");
  window.onerror = function (message, path, line, column, error) {
    void saveWebsiteError(
      error,
      {
        name: "window.onerror",
        message,
      },
      null,
      path,
      column,
      line
    );
  };
  window.addEventListener("unhandledrejection", function (event) {
    void saveWebsiteError(
      event.reason,
      {
        name: "unhandledrejection",
      },
      null
    );
  });

  Vue.config.errorHandler = function (exception, domElement, info) {
    if (info === "Uncaught SyntaxError: Unexpected token '<'") {
      console.warn("Refreshing due to cache busting.");
      window.location.reload(true);
      return;
    }
    void saveWebsiteError(
      exception,
      { name: "Vue.config.errorHandler", info },
      domElement
    );
  };
}
