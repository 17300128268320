import individualStudentRoute from "./individual-student/route";

function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "submissions",
  name: "TeacherSubmissionsRoot",
  component: lazyLoad("TeacherSubmissionsRoot"),
  props: true,
  redirect: "./",
  children: [
    {
      path: "",
      name: "TeacherSubmissionsPortal",
      component: lazyLoad("TeacherSubmissionsPortal"),
    },
    {
      path: "assignment-data",
      name: "TeacherAssignmentDataView",
      component: lazyLoad(`TeacherAssignmentDataView`),
    },
    {
      path: "assignment-expectations",
      name: "TeacherAssignmentExpectationsView",
      component: lazyLoad(`TeacherAssignmentExpectationsView`),
    },
    {
      path: "online-activity",
      name: "TeacherOnlineActivityView",
      component: lazyLoad(`TeacherOnlineActivityView`),
    },
    {
      path: "searches",
      name: "TeacherSearchesView",
      component: lazyLoad(`TeacherSearchesView`),
    },
    {
      path: "site-logs",
      name: "TeacherSiteLogsView",
      component: lazyLoad(`TeacherSiteLogsView`),
    },
    {
      path: "documents",
      name: "TeacherStudentWorkFilesView",
      component: lazyLoad(`TeacherStudentWorkFilesView`),
    },
    {
      path: "categories-of-sites",
      name: "TeacherCategoriesOfSitesView",
      component: lazyLoad(`TeacherCategoriesOfSitesView`),
    },
    {
      path: "assignment-details",
      name: "TeacherAssignmentDetailsView",
      component: lazyLoad(`TeacherAssignmentDetailsView`),
    },
    {
      path: "notes-tags-annotations",
      name: "TeacherNotesTagsAnnotationsView",
      component: lazyLoad(`TeacherNotesTagsAnnotationsView`),
    },
    {
      path: "roster",
      name: "TeacherAssignmentRosterView",
      component: lazyLoad("TeacherAssignmentRosterView"),
      props: true,
    },
    {
      path: "note-boards",
      name: "TeacherAssignmentNoteBoardsView",
      component: lazyLoad("TeacherAssignmentNoteBoardsView"),
      props: true,
    },
    individualStudentRoute,
  ],
};
