import { getExtensionControllerById } from "@/services/extensionControllers";
import "@learnics/services/src/yjsProvider/initializeCommonYjsPackage";
import Vue from "vue";
import App from "@/App";
import vuetify from "@/plugins/vuetify";
import router from "@/views/router";
import { store } from "@/store/index.js";
import { onAuthStateChanged } from "firebase/auth";
import { auth, functions } from "@learnics/services/src/analyticsConfig";
import VCalendar from "v-calendar";
import { VueMaskDirective } from "v-mask";
import ErrorService from "@learnics/services/src/error";
import { connectFunctionsEmulator } from "firebase/functions";
import { initializeCustomTipTapExtensions } from "@/utils/initializeCustomTipTapExtensions";
import { saveWebsiteError } from "@/utils/saveWebsiteError";
import { initializeGlobalWebsiteErrorHandlers } from "@/utils/initializeGlobalWebsiteErrorHandlers";

initializeCustomTipTapExtensions();

// Before we load anything, let's check if we're on the old domain and redirect to the new one
// Ideally this should be done in the hosting config, but in lieu of that, we still need to make sure
// that the old domain is not used, or the logging will not work correctly, among other things.

// first, let's log uncaught errors to database
if (location.hostname === "learnics-web-page.firebaseapp.com") {
  let manualRedirect = new URL(location.href);
  manualRedirect.hostname = "app.learnics.com";
  location.replace(manualRedirect.toString());
}

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

if (process.env.VUE_APP_EMULATOR_MODE === "true") {
  // connectFirestoreEmulator(db, "localhost", 8081);
  // connectAuthEmulator(auth, "http://localhost:9099");
  // onAuthStateChanged(auth, function (user) {
  //   if (user) {
  //     // User is signed in.
  //     console.log(user.displayName);
  //   } else {
  //     // No user is signed in.
  //     console.log("Not signed in yet.  Signing in with a fake user...");
  //     // sign in with fake user
  //     signInWithCredential(
  //       auth,
  //       GoogleAuthProvider.credential(
  //         '{"name":"Sally Foo", "sub":"abc123", "email": "foo@example.com", "email_verified": true}'
  //       )
  //     )
  //       .then((userCredential) => {
  //         console.log("User sign in success", userCredential);
  //       })
  //       .catch((error) => {
  //         console.log("User sign in error", error);
  //       });
  //   }
  // });
  connectFunctionsEmulator(functions, "localhost", 5001);
}
initializeGlobalWebsiteErrorHandlers();

// Add an event listener to listen for messages from extensions
window.addEventListener("message", async function (event) {
  const data = event?.data;
  if (event.data.type === "learnicsExtensionMessage") {
    console.log("Got message at learnics app", event.data);
    // Process messages from extensions.  These are messages from the service
    // worker meant for individual instances of the web app.
    // Our extension controllers all have message handlers who will handle
    // the message.
    const message = data?.data?.message;
    const sender = data?.sender;

    const extension = getExtensionControllerById(event.data.extensionId);
    if (!extension) {
      console.error("Extension not found", event.data.extensionId);
      return;
    }
    await extension.messageHandler.processMessage(message, sender);
  }
});

Vue.directive("mask", VueMaskDirective);

Vue.use(VCalendar, {
  componentPrefix: "vc",
});
Vue.config.productionTip = false;

let app = null;

onAuthStateChanged(auth, async function (user) {
  // get the custom claims for the user
  if (user) {
    const token = await user.getIdTokenResult(true);
    const claims = token.claims || {};
    await store.dispatch("updateUser", {
      user,
      claims,
    });
  }
  if (!app) {
    app = "loading";
    void loadApp();
  }
});

async function loadApp() {
  app = new Vue({
    el: "#app",
    vuetify,
    router,
    store,
    render: (h) => h(App),
  });
  store.commit("setUserFilesBucketName", process.env.VUE_APP_USER_FILES_BUCKET);
}
