import sessionRoute from "@/views/popups/session/route";
import assignmentRoute from "@/views/popups/assignment/route";

function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}
export default {
  path: "/popups",
  props: true,
  component: lazyLoad("PopupsRoot"),
  children: [
    // @deprecated route - remove after student-link 3.40.2 is widely distributed.
    {
      path: "stoppedLoggingMessage",
      name: "StoppedLoggingMessageView",
      props: true,
      component: lazyLoad("StoppedLoggingMessageView"),
    },
    // @deprecated route - remove after student-link 3.41.1 is widely distributed.
    {
      path: "stoppedLoggingAssignment/:assignment_id",
      name: "StoppedLoggingAssignmentView",
      props: true,
      component: lazyLoad("StoppedLoggingAssignmentView"),
    },
    sessionRoute,
    assignmentRoute,

    {
      path: "getCustomToken",
      name: "GetCustomTokenView",
      component: lazyLoad("GetCustomTokenView"),
    },
    {
      path: "localHostSignIn",
      name: "LocalHostSignInView",
      component: lazyLoad("LocalHostSignInView"),
    },
    {
      path: "customTokenSignIn",
      name: "CustomTokenSignInView",
      component: lazyLoad("CustomTokenSignInView"),
    },
    // @deprecated route - remove after student-link 3.48.0 is widely distributed.
    {
      path: "extensionError/:installation_id",
      props: true,
      name: "ExtensionErrorView",
      component: lazyLoad("ExtensionErrorView"),
    },
    // end @deprecated route
    {
      path: "error/:error_id",
      props: true,
      name: "ExtensionErrorView2",
      component: lazyLoad("ExtensionErrorView2"),
    },
  ],
};
