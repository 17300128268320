function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "submissions",
  name: "StudentSubmissionsRoot",
  component: lazyLoad("StudentSubmissionsRoot"),
  redirect: "./",

  children: [
    {
      path: "",
      name: "StudentSubmissionsPortal",
      component: lazyLoad("StudentSubmissionsPortal"),
    },

    {
      path: "assignment-data",
      name: "StudentAssignmentDataView",
      component: lazyLoad("StudentAssignmentDataView"),
    },
    {
      path: "assignment-details",
      name: "StudentAssignmentDetailsView",
      component: lazyLoad("StudentAssignmentDetailsView"),
    },
    {
      path: "bibliography",
      name: "StudentBibliographyView",
      component: lazyLoad("StudentBibliographyView"),
    },

    {
      path: "note-boards",
      name: "StudentAssignmentNoteBoardsView",
      component: lazyLoad("StudentAssignmentNoteBoardsView"),
      props: true,
    },
  ],
};
