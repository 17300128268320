import ErrorService from "@learnics/services/src/error";

export async function saveWebsiteError(
  exception = { message: "Unknown error", stack: "" },
  context = {},
  domElement = null,
  column = null,
  line = null,
  forced = false
) {
  console.error("===== saveWebsiteError =====");
  console.error("Error:", exception);
  console.error("Context:", context);
  console.error("DOM Element:", domElement);
  console.error("Column:", column);
  console.error("Line:", line);
  if (!forced && !window.location.origin.includes("localhost")) {
    return;
  }
  return await ErrorService.saveError3(
    exception,
    context,
    {
      id: "learnics-app",
      version: process.env.VUE_APP_VERSION,
    },
    domElement,
    column,
    line
  );
}
